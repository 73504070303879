.bori1{

    font-size: 5rem;
    margin-bottom: 30px;
    color: black;
    
    line-height: 1.1;
    
    font-family: 'Noto Sans KR';
    font-weight: 900;
    

}
.bori2{

    font-size: 3rem;
    margin-top: 2vh;
    color: black;
    
    line-height: 1.1;
    
    font-family: 'Noto Sans KR';
    font-weight: 300;
    word-break : keep-all;
    

}
.bori3{

    font-size: 3rem;
    margin-bottom: 30px;
    color: black;
    
    line-height: 1.1;
    
    font-family: 'Noto Sans KR';
    font-weight: 500;
    word-break : keep-all;
    

}
.bori4{

    font-size: 5rem;
    margin-bottom: 30px;
    color: black;
    
    line-height: 1.1;
    
    font-family: 'Noto Sans KR';
    font-weight: 700;
    

}

.boriPlan{
    font-size: 3rem;
    margin-bottom: 30px;
    color: black;
    
    line-height: 1.5;
    
    font-family: 'Noto Sans KR';
    font-weight: 400;

}

.boriPlan2{
    font-size: 3rem;
    margin-bottom: 30px;
    color: black;
    
    line-height: 1.5;
    
    font-family: 'Noto Sans KR';
    font-weight: 700;
    background: linear-gradient(

        to bottom,
        transparent 50%,
        yellow 50%

    );
    


}

.borilun{
    font-size: 3rem;
    margin-bottom: 30px;
    color: black;
    
    line-height: 1.5;
    font-family: 'Noto Sans KR';
    font-weight: 200;
    
}


.boriBodyW{

    padding-top: 40px;

   

}

.borib{

    padding-top: 50px;

    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 7rem;
    
    color: black;
    line-height: 4;
}


@media screen and (max-width: 991px) {
 

    .bori1{

        font-size: 3rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 1.1;
        
        font-family: 'Noto Sans KR';
        font-weight: 900;
        
    
    }
    .bori2{
    
        font-size: 2.5rem;
        
        color: black;
        
        line-height: 1.5;
        
        
        font-family: 'Noto Sans KR';
        font-weight: 300;
        word-break : keep-all;
        
    
    }
    .bori3{
    
        font-size: 2.5rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 1.1;
        
        font-family: 'Noto Sans KR';
        font-weight: 500;
        word-break : keep-all;
        
    
    }
    .bori4{
    
        font-size: 3rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 1.5;
        
        font-family: 'Noto Sans KR';
        font-weight: 700;
        
    
    }

  }




@media screen and (max-width: 768px) {


 .boriFbodyTitle{

    min-height: 350px;

 }


 
.borib{

    padding-top: 90px;

    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 3rem;
    
    color: black;
    line-height: 2.2;
}
    
    .boriPlan2{
        font-size: 2rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 2.4;
        
        font-family: 'Noto Sans KR';
        font-weight: 700;
        background: linear-gradient(
    
            to bottom,
            transparent 50%,
            yellow 50%
    
        ) }



    .bori1{

        font-size: 2.5rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 1.1;
        
        font-family: 'Noto Sans KR';
        font-weight: 500;
        
    
    }
    .bori2{
    
        font-size: 3rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 1.5;
        
        font-family: 'Noto Sans KR';
        font-weight: 300;
        word-break : keep-all;
        
    
    }
    .bori3{
    
        font-size: 2.0rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 1.1;
        
        font-family: 'Noto Sans KR';
        font-weight: 500;
        word-break : keep-all;
        
    
    }
    .bori4{
    
        font-size: 4rem;
        margin-bottom: 30px;
        color: black;
        
        line-height: 1.5;
        
        font-family: 'Noto Sans KR';
        font-weight: 700;
        
    
    }
 
    .boriPlan{
        font-size: 2rem;
        margin-bottom: 30px;
        color: black;
        

        padding-top: 5vh;
        line-height: 2;
        
        font-family: 'Noto Sans KR';
        font-weight: 400;
    



    }
    
    .boriBodyW{

        margin : 4vw;
        
        padding: 0;
        
    }
    
    .borilun{
        font-size: 1.5rem;
        
      
        
    }
    
    

}



@media screen and (max-height: 768px) {

    .boriBodyW{

        margin : 4vw;
        /* background-color: red; */
        min-height: 150px;
    
    }


  
 
}




