

.socialbody{

    
    height : 66vh;
    margin: 55px;
    margin-top: 20vh;
}

.socialCard{

    background-color: red;

  justify-content: center;
  display: flex;

  width : 500px;
  height : 300px;
  position : static;

  top : 0%;
  left : 0%;
  border-radius: 16px;
  padding : 10px;
}

.cardWrapper{
  
    max-height: 400px;
}

.CardImg{
  
  overflow: hidden;
  object-fit: cover;
  
  position: relative;
  
  
  
}




.socialImg{
  
  height: 100%;
  width: 400px auto;	
  
  overflow: hidden;
  
  

}





.socialimgTitle{

    
    text-align: right;
    margin-right: 3vw;
}




/* //이부분으로 조정 */
button.rec-dot{
    background-color: white;
    box-shadow: 0 0 1px 1px grey;
  }
  
  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
    box-shadow: 0 0 1px 3px rgba(235,16,16,0.5);
  }

  .rec.rec-arrow:disabled {
    visibility: hidden;
}


.rec.rec-arrow {
    background-color: white;
    box-shadow: 0 0 1px 1px white;
}

.rec.rec-arrow:hover {
    border-radius: 50%;
}



button.rec-arrow:hover, button.rec-arrow:active, button.arrow-dot:focus  {
    box-shadow: 0 0 1px 3px #103F83;
  }

 
  
.rec.rec-arrow {
    background-color: white;
    box-shadow: 0 0 1px 1px white;
}

.rec.rec-arrow:hover {
    border-radius: 50%;
    background-color: white;
}





/* //버튼부분 */
.rec.rec-carousel-wrapper .rec-carousel button {
    background: white;
    color: black;
    border-radius: 50%;
    
  }

  .socialTitle{

    margin: 50px;
    margin-Top: 10vh;
    margin-bottom: 10vh;

  }


  .socialContexts1{

    font-size: 70px;
    font-family: 'Noto Sans KR';
    font-weight: 500;
    
    color: black;

  }
  .socialContexts2{


    font-size: 50px;
    font-family: 'Noto Sans KR';
    font-weight: 500;
    line-height: 5vh;
    
    color: black;

  }
  .socialContexts3{


    font-size: 1rem;
    font-family: 'Noto Sans KR';
    font-weight: 300;
    
    color: black;

  }



  @media screen and (max-height: 980px) {



    .CardImg{
      
      overflow: hidden;
      object-fit: cover;
      height: 250px;
      position: relative;

    }


    
    .cardWrapper{
      height: 250px;


    }



  }



  

@media screen and (max-width: 991px) {



  .socialbody{

    
        height : 60vh;
        margin: 55px;
        margin-top: 20vh;
    }


 
    .socialTitle{

        margin: 50px;
        margin-Top: 10vh;
        margin-bottom: 10vh;
    
      }
    
    
      .socialContexts1{
    
        font-size: 3rem;
        font-family: 'Noto Sans KR';
        font-weight: 700;
        color: black;
        word-break : keep-all;
    
      }
    
      .socialContexts2{
    
    
        font-size: 1.5rem;
        font-family: 'Noto Sans KR';
        font-weight: 400;
        line-height: 3vh;
        
        margin-top: 1vh;
        color: black;
    
      }
      .socialContexts3{
    
    
        font-size: 0.9rem;
        font-family: 'Noto Sans KR';
        font-weight: 300;
        
        color: black;
    
      }



      .CardImg{
        
        overflow: hidden;
        object-fit: cover;
        height: 200px;
        position: relative;
        
        width:90%; 
        text-align:center; 
      }
      
      

  }
  

  
@media screen and (max-width: 768px) {
    .socialbody{
        
    width: 100vw;
        
      
      height : 600px;
        margin: 0px;
        margin-top: 130px;
        margin-bottom: 150px;
        padding: 0;




        
        


    }


    
  .socialTitle{

    margin: 50px;
    margin-Top: 10vh;
    margin-bottom: 10vh;

  }


  .socialContexts1{

    font-size: 3rem;
    font-family: 'Noto Sans KR';
    font-weight: 700;
    color: black;
    word-break : keep-all;

  }

  .socialContexts2{


    font-size: 1.5rem;
    font-family: 'Noto Sans KR';
    font-weight: 400;
    line-height: 3vh;
    
    margin-top: 1vh;
    color: black;

  }
  .socialContexts3{


    font-size: 0.7rem;
    font-family: 'Noto Sans KR';
    font-weight: 300;
    line-height: 4vh;
    color: black;

  }

  .socialimgTitle{


  }
 


  .CardImg{
    background-color: red;
    overflow: hidden;
    object-fit: cover;
    height: 140px;
    position: relative;
    width: 100%;
  }
  .cardWrapper{
    height: 140px;

  }

}