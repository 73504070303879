html, body { overflow-x: hidden; }


.mainFisrtBody{
    margin-top: 9vh;
    background: white;
    width: 100vw;
    height: 100vh;
}



/* 
.mainFisrtText{
    
    height: 100vh;
    padding : 50px ;
    margin: 50px;
    text-align: center;
   

}

 */


/* 
이방법은 이미지 유지는 되는데 PC에서 원하는 사이즈 조절이 안댐
.imageW{

    width : 100%;


}*/
 
.mainFirstimg{
 
 
    
  
  width: 70%;
  height: auto;
  object-fit: cover;
  
  position: relative;
  top: 15%;
  


} 



.imageW{

    background-color: white;

    justify-content: center;
    display: flex;
    
    position : static;
    
  
    height: 500px;
    padding: 0px;


}

 
.mainFcontext{

    margin-top: 30vh;
   

}

.safeLife{
    font-weight: 700;
    font-size: 3rem;

}

.mainFCompanyname{
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;

}

.mainFcompany{
    

}




@media screen and (max-width: 768px) {

    .imageW{
        
        background-color: white;

        justify-content: center;
        display: flex;
        
        position : static;
        
      
        height: 450px;
        padding: 0px;
    
        
          }



          .mainFirstimg{
 
 
    
  
            width: 100%;
            height: auto;
            object-fit: cover;
            
            position: relative;
            
          
          
          } 
          

          

          .safeLife{
            font-weight: 700;
            font-size: 1.6rem;
           
        }

        .mainFcontext{
            word-break : keep-all;
        }
        

        .mainFisrtBody{
            background: white;
            width: 100vw;
            height: 700px;
        }


}