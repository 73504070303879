 

section{

    position : relative;
    width : 100%;
    height : 34vh;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    
    
}


section .row {

 position : relative;
 width : 100%;
    display : flex;
 padding : 10px 0;
    white-space: nowrap;
font-size: 64px;




/* transform: rotate(-5deg); */


}




i{

color : white;
transition : 1s;
padding : 0 5px;
user-select: none;
cursor : default;


}

/* 
img{

    width: 400px;
    height: auto;

} */


i:hover {


}


section .row div {


animation: animate 130s linear infinite;

animation-delay: -45s;

}


section .row div:nth-child(2) {

animation: animate2 130s linear infinite;
animation-delay: -45s;

}


.reviewImg{

    border-radius: 30px;
    

}



@keyframes animate {

    0%{
        transform:  translateX(0%);
    }

    100% {
        transform : translateX(-340%);
    }


}



@keyframes animate2 {

    0%{
        transform:  translateX(-340%);
    }


    100% {
        transform: translateX(0%);
    }




}






section .row:nth-child(even) div {

animation: animate3 45s linear infinite;
animation-delay: -40s;

}


section .row:nth-child(even) div:nth-child(2) {

animation: animate4 80s linear infinite;
animation-delay: -40s;

}





@keyframes animate3 {
    0%{
        transform:  translateX(0%);
    }

    100% {
        transform : translateX(-340%);
    }




}



@keyframes animate4 {


    0%{
        transform:  translateX(-340%);
    }


    100% {
        transform: translateX(0%);
    }





}


@media screen and (max-width: 768px) {


 




}




