

.bottomContext{

    background-color: black;   

    height: 160px;
      
    width : 100vw;

    text-align: right;
    padding-right: 40px;
    padding-top: 25px;

}



.bottomText1{
   
    color: white;

  font-weight: 500;
  font-size: 1.1rem;
  line-height: 0.10;
  text-align: right;

}

.bottomText2{
    color: white;

  font-family: 'Noto Sans KR';
  font-weight: 100;
  font-size: 1rem;
  line-height: 1;
  text-align: right;

}
.bottomText3{
    color: white;
  font-weight: 100;
  font-size: 0.8rem;
  line-height: 0.2;
  text-align: right;

}






@media screen and (max-width: 991px) {
 
 
.bottomContext{

    background-color: black;   

    height: 10vh;
      
    width : 100vw;

    text-align: right;
    padding-right: 40px;

}


.bottomText{
    
    color : white;
    padding-top : 0px;
    padding-bottom : 0px;
    height: 10vh;

}



.bottomText1{
   color: white;
  font-family: 'Noto Sans KR';
  font-weight: 100;
  font-size: 0.8rem;
  line-height: 0.7;
  text-align: center;

}


  }




@media screen and (max-width: 768px) {

  .bottomContext{

    background-color: black;   
  
    height: 140px;
      
    width : 100vw;
  
    text-align: right;
    padding-right: 20px;
    padding-top: 25px;
  
  }
  

.bottomText1{
    color: white;

  font-family: 'Noto Sans KR';
  font-weight: 100;
  font-size: 0.8rem;
  line-height: 0.2;
  text-align: right;

}

.bottomText2{
    color: white;

  font-family: 'Noto Sans KR';
  font-weight: 100;
  font-size: 0.8rem;
  line-height: 0.10;
  text-align: right;

}
.bottomText3{
    color: white;

  font-weight: 100;
  font-size: 0.1rem;
  line-height: 0.10;
  text-align: right;

}







}






@media screen and (max-height: 900px) {



.bottomText1{
    color: white;

  font-family: 'Noto Sans KR';
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1;
  text-align: right;

}
  
.bottomContext{

  background-color: black;   

  height: 180px;
    
  width : 100vw;

  text-align: right;
  padding-right: 20px;
  padding-top: 25px;
  

}




}
