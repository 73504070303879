/* 기본 부분 배경임 */

.header{
  background: white;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 8vh;
  
  /* 이거 중요하다! */
  z-index: 999;

}
/* 
.navbar {
    background: white;
    height: 80px;
    display:  flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: absolute;
    top: 0;
    z-index: 999;
  }
   */

  .navbar-container {
    
    
    background: white;
    display: flex;
    justify-content: space-between;
    height: 8vh;
    
  }
  
  
  .vbcontainer {
    z-index: 1;
    width: 200vw;
    max-width: 100vw;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    margin-top: 0;
    padding-top: 0;
  }
  

  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 1px solid transparent;
  }
  
  /* 넓은 화면 / 아래부분 밑줄 */
  .nav-item:hover {
    border-bottom: 2px solid #103F83;

  }
  
  /* 폰트 컬러부분 */
  .nav-links {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    cursor: pointer;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  .phoneIcon {
    display: none;
  }


  


  @media screen and (max-width: 960px)  {


    

    .header{
      background: white;
      position: fixed;
      top: 0;
      width: 100vw;
      height: 8vh;
      
      /* 이거 중요하다! */
      z-index: 999;
    
    }



    .navbar-container {
      background: white;
      display: flex;
      justify-content: space-between;
      height: 8vh;
      padding-left: 10px;
      padding-top: 0px;


    }
    
    .NavbarItems {
      position: relative;
    }
  

    /* 이부분이 네이브바 위치 범위  */
    /* 아래 부분이 메뉴 높이를 지정함 */
    .nav-menu {
      /* display: flex;
      flex-direction: column;
      width: 105%;
      height: 102vh;
      position: absolute; */

      /* 이부분이 메뉴 부분 타부분과 간격  */
      /* top: 68px;
      left: -90%;
      opacity: 1;
      transition: all 0.1s ease; */

      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 68px;
      left: -100%;
      opacity: 0.8;
      transition: all 0.5s ease;
      padding-bottom: 8vh;
    }
  
    
    
    /* 네이브바 내부 이미지색  */
    .nav-menu.active {
      background: black;
      left: 0;
      opacity: 0.94;
      transition: all 0.5s ease;
      z-index: 1;
    }
  


    /* 네이브바 안에 범위를 지정하게 됌  div와 같은 형식 */
    .nav-links {
      color: white;
      
    
        
      /* 해당부분을 absolute, relative 하면 제위치에 먹힘 */
      /* relative를 할 경우, 마우스를 갖다 대었을대 그대로 올라옴 */
      /* 이부분이 바 안에 내용 위치 */
      /* position:absolute;  */
      top : 40%;
      width: 100%;
      

    /* 이게 문제 였음 클릭하는 부분에 애매하게 잡혔는데, 이 부분을 잡아냄 
    아래부분을 지정해야 텍스트 가 중앙으로 정렬됌*/
      display: table;
    
    }
  

    .nav-links:hover {
      color: white;
      transform: scale(1.3);
      transition: all 0.3s ease;
    }


    .nav-item:hover {
      border: none;
      /* border-bottom: 2px solid white; */
    }
  


    /* 미디어쿼리 영역 부분에 메뉴란 */
    /* 아래부분을 조종하니깐 div간의 간격이 줄어들었다 */
    .nav-item {
     
      width: 90%;
      height : 21%;
      margin-left: -8vw;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  

    .navMlogo{
      /* display: block; */
      position: absolute;
      
      width: 100%;

      
      
      display: flex;
      justify-content: center;
      align-items: center;

      padding-right: 13px;
      
      

    }

    .nvLogo{
      
      width: 7vh;
     
    }
    
    .menu-icon {
      
      display: block;
      position: absolute;
      top: -20%;
      left : 50px;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      
      /* //손가락 모양 나오게
      cursor: pointer; */

    }


    .phoneIcon{

      display: block;
      position: absolute;
      top: -20%;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      
      /* //손가락 모양 나오게
      cursor: pointer; */
      

    }



  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }

  }



  

@media screen and (max-width: 768px) {

 
  .navbar-container {
    background: white;
    display: flex;
    justify-content: space-between;
    
    height: 60px;
    padding-left: 10px;
    padding-bottom: 1vh;


  }
   
/* 기본 부분 배경임 */

.header{
  background: white;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 8vh;
  
  /* 이거 중요하다! */
  z-index: 999;

}

.nvLogo{
  
  width: 60px;
  
}


.nav-menu.active {
  background: black;
  left: 0;
  opacity: 0.96;
  transition: all 0.5s ease;
  z-index: 1;
  padding: 70px;
  
}

/* 메뉴 블록 */

.nav-item {

  width: 90%;
  height : 20vh;
  margin-left: 2vw;




}



/* 네이브바 안에 범위를 지정하게 됌  div와 같은 형식 */
.nav-links {
  /* color: blue; */
  
    
  
 
  display: table;

  font-size: 1.2rem;
  color: white;
  

  /* padding-top: 5vh; */
  
  font-family: 'Noto Sans KR';
  font-weight: 300;




}




}







@media screen and (max-height: 991px) {



 
  .navbar-container {
    background: white;
    display: flex;
    justify-content: space-between;
    
    height: 60px;
    padding-left: 10px;
    padding-bottom: 1vh;


  }
   
/* 기본 부분 배경임 */

.header{
  background: white;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 8vh;
  
  /* 이거 중요하다! */
  z-index: 999;

}

.nvLogo{
  
  width: 60px;
  
}





}

