

.roadBody{
/* 
    padding-top: 14vh;
    background-color: #re;
    
    height: 100vh;
    width : 100%;
    position : relative;
     */

}

.roadContentCenter{




}

.roadImg{

    background-color: #0F4B81;

    justify-content: center;
    display: flex;
    
    position : static;
    
    border-radius: 7px;
    
    width: 50vw;
    height: 500px;

    margin-left: 2vw;
    overflow: hidden;
}

.roadImgMe{
       
    border-radius: 8px;
    width: 800px;
  
    position: relative;
    bottom: 15%;
  }


.RoadWait{
  font-size: 5rem;
  margin-bottom: 30px;
  color: black;
  
  line-height: 1;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;

  line-height: 20vh;

  margin-top: 25vh;


}

/* .RoadWait2{
  font-size: 2rem;
  margin-bottom: 30px;
  color: black;
  
  line-height: 1;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  
} */
.RoadWhen{
  font-size: 2rem;
  margin-bottom: 30px;
  color: black;
  
  line-height: 1;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  
}



.roadTitle{
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: white;
  
  line-height: 0;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  margin-left: 1vw;

}

.roadEx{

  font-size: 1rem;
  margin-bottom: 30px;
  color: white;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 200;
  margin-left: 3vw;


}

.dSmart{
  font-size: 1.5rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;
  text-align: left;
  
}

.dBlog{

  font-size: 1.5rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;
text-align: left;
}

.dCafe{
  font-size: 1.5rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;
  text-align: left;
  
}

.dyoutube{
  font-size: 1.5rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;
  text-align: left;
  padding-left: 1vw;
  


}


.channelWrap{

   margin-left: 0.5vw;
  
  


  }


.roadLink{
  font-size: 1.7rem;
  color: white;
  
  line-height: 1;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;
  margin-left: 3vw;


}

.youtubeLogo{

  
  max-width: 60px;
  margin-bottom: 1vh;
  margin-right: 20px;


}


.DnationSStore{
  max-width: 500vw;
  width : 19vw;
  background-color: white;

  margin-bottom: 2vh;
  padding : 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;


   cursor: pointer;
   margin-left: 5vw;




}




/* 모니터 반쪽 세로 최대 */

@media screen and (max-height: 991px) {

  
  .DnationSStore{

    width : 380px;
    background-color: white;
  
    margin-bottom: 2vh;
    padding : 20px;
    border-top-right-radius: 20px;
     cursor: pointer;
     margin-left: 5vw;
  
  }



  .roadImg{
    width: 50vw;
    /* background-color: yellow; */
  
  }




}






/* 이부분은 팝업형태 사이즈 반의 반 //팝업상태의 모양임*/

  @media screen and (max-width: 991px) {







    .roadBody{

      padding-top: 14vh;
      /* background-color: red; */
      
      height: 2vh;
      width : 100vw;
      position : relative;
      
  
  }


  .DnationSStore{
    width : 30vw;
    background-color: white;
  
    margin-bottom: 2vh;
    padding : 20px;
    border-top-right-radius: 20px;
     cursor: pointer;
     margin-left: 5vw;

     
  
  }
  
  

/* 반쪽에서 높이 줄이고 옆으로 줄일때 */
.roadImg{
  
  background-color: #0F4B81;
  height: 400px;
  

}
.roadImgMe{
  
  
  
}
  

  
.dSmart{
  font-size: 1rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  text-align: left;
}

.dBlog{

  font-size: 1.5rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  text-align: left;
}

.dCafe{
  font-size: 1.5rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  text-align: left;
  
}

.dyoutube{
  font-size: 1.2rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  text-align: left;
  padding-left: 1vw;
  


}

.youtubeLogo{

  
  max-width: 50px;


}


  }




@media screen and (max-width: 768px) {
/* 
    .roadImg{
        padding : 1px;
        width: auto;      
          height: auto;
        
          }

          .roadImgMe{

            border-radius: 8px;
          }
 */



 .roadImg{

  


}

 
 .roadBody{

  padding-top: 14vh;
  
  
  height: 100vh;
  width : 100%;
  position : relative;
  

}


          


 .RoadWait{
  font-size: 2rem;
  margin-bottom: 30px;
  color: white;
  
  line-height: 1;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;


}


.roadTitle{
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: white;
  
  line-height: 0;
  
  font-family: 'Noto Sans KR';
  font-weight: 400;
  text-align: right;

}

.roadEx{

  font-size: 0.8rem;
  margin-top: 1.2vh;
  margin-bottom: 30px;
  color: white;
  
  line-height: 1.5;
  
  font-family: 'Noto Sans KR';
  font-weight: 200;
  word-break : keep-all;


}

.dSmart{
  font-size: 1rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  text-align: left;
}

.dBlog{

  font-size: 1rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
text-align: left;
}

.dCafe{
  font-size: 1rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  text-align: left;
  
}

.dyoutube{
  font-size: 1rem;
  color: black;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 500;
  text-align: left;
  padding-left: 1vw;
  


}


.channelWrap{

   margin-left: 0.5vw;
  
  }


.roadLink{
  padding-top: 5vh;
  font-size: 1.7rem;
  color: white;
  
  line-height: 2;
  
  font-family: 'Noto Sans KR';
  font-weight: 600;



}

.youtubeLogo{

  
  max-width: 11vw;
  margin-bottom: 1vh;


}

.DnationSStore{
  width : 70vw;
  background-color: white;
  margin-bottom: 3vh;


}


.roadContentCenter{

  width : 100vw;


}


.RoadWait{
  font-size: 2.5rem;
  margin-top: 18vh;
  
  color: black;
  
  
  font-family: 'Noto Sans KR';
  font-weight: 600;


  


}

.RoadWait2{
  font-size: 1.2rem;
  padding-bottom: 3vh;
  color: black;
  
  
  
  font-family: 'Noto Sans KR';
  font-weight: 300;
  
  
}



}






 








@media screen and (max-width: 768px) {
  /* 
      .roadImg{
          padding : 1px;
          width: auto;      
            height: auto;
          
            }
  
            .roadImgMe{
  
              border-radius: 8px;
            }
   */
  

   .RoadWhen{
    font-size: 1.7rem;
    
    font-weight: 200;
    
    
  }
  
            .roadFbodyTitle{
              background-color: white;          
              max-height: 180px;
            }


            .roadImg{
              padding : 1px;
              width: 75vw;      
                height: 40vh;
              
                }
  

           



   .roadBody{
  
    padding-top: 14vh;
    
    

    height: 100vh;
    width : 100vw;
    position : relative;
    
  
  }
  
  
            
  
  
   .RoadWait{
    font-size: 2rem;
    margin-bottom: 30px;
    color: white;
    
    line-height: 1;
    
    font-family: 'Noto Sans KR';
    font-weight: 600;
    
    
  
  }
  
  
  .roadTitle{
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: white;
    
    line-height: 0;
    
    font-family: 'Noto Sans KR';
    font-weight: 400;
    text-align: right;
  
  }
  
  .roadEx{
  
    font-size: 0.8rem;
    margin-top: 1.2vh;
    margin-bottom: 30px;
    color: white;
    
    line-height: 1.5;
    
    font-family: 'Noto Sans KR';
    font-weight: 200;
    word-break : keep-all;
  
  
  }
  
  .dSmart{
    font-size: 1rem;
    color: black;
    
    line-height: 2;
    
    font-family: 'Noto Sans KR';
    font-weight: 500;
    text-align: left;
  }
  
  .dBlog{
  
    font-size: 1rem;
    color: black;
    
    line-height: 2;
    
    font-family: 'Noto Sans KR';
    font-weight: 500;
  text-align: left;
  }
  
  .dCafe{
    font-size: 1rem;
    color: black;
    
    line-height: 2;
    
    font-family: 'Noto Sans KR';
    font-weight: 500;
    text-align: left;
    
  }
  
  .dyoutube{
    font-size: 1.2rem;
    color: black;
    
    
    font-family: 'Noto Sans KR';
    font-weight: 700;
    


    
  
  
  }
  
  
  .channelWrap{
  
     margin-left: 0.5vw;
    
    }
  
  
  .roadLink{
    padding-top: 5vh;
    font-size: 1.7rem;
    color: white;
    
    line-height: 2;
    
    font-family: 'Noto Sans KR';
    font-weight: 600;
  
    display: flex;
    justify-content: center;
    /* background-color: black; */
    
    width : 50vw;
    margin-inline-start: 25vw;
  
  }
  
  .youtubeLogo{
  
    
    width: 300px;
  
  
  }
  
  /* .DnationSStore{
    width : 50vw;
    background-color: royalblue;
    margin-bottom: 3vh;
    margin-inline-start: 25vw;
  

    display: flex;
    justify-content: center;

  }
   */
  
  .roadContentCenter{
  
    width : 100vw;
  
  
  }
  
  
  .RoadWait{
    font-size: 2.5rem;
    margin-top: 18vh;
    
    color: black;
    
    
    font-family: 'Noto Sans KR';
    font-weight: 600;

    margin-top: 25vh;
  
    
  
  
  }
  
  .RoadWait2{
    font-size: 1.2rem;
    padding-bottom: 3vh;
    color: black;
    
    
    
    font-family: 'Noto Sans KR';
    font-weight: 300;
    
    
  }
  
  
   











  
  
  }
  
  @media screen and (max-width: 430px) {
  

.roadImg{

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0F4B81;
  height: 300px;
  overflow: hidden;
  
}


.roadImgMe{


  
  max-width: 190%;
  
  position: relative;
  bottom: 15%;
  

}


    .DnationSStore{
      width : 75vw;
      background-color: white;
      margin-bottom: 3vh;
      margin-inline-start: 5vw;
    
      display: flex;
      justify-content: left;
      

    }


    .dSmart{
      font-size: 1.1rem;
      color: black;
      
      padding-top: 5px;
      
      font-family: 'Noto Sans KR';
      font-weight: 500;
      text-align: left;

    }
    
    
  .roadLink{
    padding-top: 5vh;
    font-size: 1.7rem;
    color: white;
    
    line-height: 2;
    
    font-family: 'Noto Sans KR';
    font-weight: 600;
  
    display: flex;
    justify-content: center;
    /* background-color: red; */
    
    width : 50vw;
    margin-inline-start: 1vw;
  
  }
  
  
  }

  






