html, body { overflow-x: hidden; }


.introBB{

  max-width: 100vw;

}


.introContentsContainer{

    background-color: white;
    border-radius : 80px;
    width : 420px;
    height : 700px;
    margin-bottom: 10vh;
    margin-top : 6vh;
   padding-top: 50px;


}



.intro__Backgound_color{

    background: #103F83;
    color : white;

    height: 900px;

}


/* 해당 부분 라인 */
.home__hero-row {
  align-items: center;

}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}


.col{

    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 15%;

}

.introCCTV1{

  font-size: 90px;



  font-family: 'Noto Sans KR';
  font-weight: 700;

  font-size: 5rem;

  color: white;

}

.introCCTV2{
  margin-bottom: 30px;
  color: yellow;

  line-height: 1.5;

  font-family: 'Noto Sans KR';
  font-weight: 500;
  font-size: 2.3rem;


}

.introCCTV3{
  margin-bottom: 30px;

  color: white;

  font-family: 'Noto Sans KR';
  font-weight: 500;
  font-size: 2.3rem;



}


.introContents{
  color: black;

  font-family: 'Noto Sans KR';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;

}

.dnationCEO{
  color: white;

  font-family: 'Noto Sans KR';
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 6;
  text-align: center;

}

.introLogo{

  color: white;

  font-family: 'Noto Sans KR';
  font-weight: 300;
  font-size: 1rem;
  text-align: right;

}




img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }


.introImgWrapper{
    max-width: 555px;
}

.introTextWrapper{
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}


.introImg{
    max-width: 100%;
    margin-top: 15%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 15%;
    padding-right: 0;

}


/* 카드형태임 */
.introImgDiv{

  background-color: white;

  /* 아래는 div안에 내용임 */
  justify-content: center;
  display: flex;

  position : static;

  border-radius: 7px;

  padding-top: 4vh;

  
  margin-bottom: 0;
  height: 23vh;
  border-radius: 16px;



}


.introImgSize{

  width: auto;
  height: 15vh;

}


.introImgDivTest{

  width:18vw;
  height:220px;
  margin:0 auto;


}


.introMVDiv{
  background-color: white;

  justify-content: center;
  display: flex;

  position : static;

  top : 0%;
  left : 0%;
  border-radius: 16px;

  background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5)), url('./../../public/MVIMG.png');


  background-size: cover;
  background-repeat: no-repeat;




  width:18vw;
  height:220px;
  overflow:hidden;
  margin:0 auto;



}


.introMVicon{

 z-index: 2;
 margin-top: 6vh;
 width: 100px;
 height: 100px;
 cursor: pointer;
}

.introMViconBox{

  color : #fff;

  margin-top: 6vh;
  padding : 50px;
  /* background: rgba(255,255,255,.3); */
  border-radius : 50%;
  position : absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;


  width: 15px;

  height: 15px;

  



}

/* .introMViconBox:before {




  content : '';
  position : absolute;
  top : 0;
  left : 0px;
  right : 0;
  bottom : 0;
  border-radius: 50%;
  border : 5px solid #e5a84f;

  animation : animate 1.5s linear infinite;
  opacity : 0;


} */

.introMViconBox:after {


  content : '';
  position : absolute;
  top : 0;
  left : 0;
  right : 0;
  bottom : 0;
  border-radius: 50%;
  border : 1px solid #fff;

  animation : animated 1.6s linear infinite;
  opacity : 0;
  animation-delay : 0.05s;

}

@keyframes animate
{
  0%
  {
    transform : scale(.5);
  }

  50%{

    opacity: 1;

  }

  100%{


    transform : scale(1.5);

  }


}

@keyframes animated {
  0%{
    transform : scale(.5);
  }

  50%{

    opacity: 1;

  }

  100%{


    transform : scale(1.5);

  }


}





.dnationCEO{
  display: flex;
  justify-content: right;

}

.introMV{
  max-width: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
}

.intro_right{
  align-items: right;

}

.container{
  max-width: 85%;

}



/* 이미지 꽉차게 */

.introMVDiv{

   /* width:380px;
    height:220px;
    overflow:hidden;
    margin:0 auto; */

/*
    background-color: rgb(0,0,0,.7);
    color : #fff;
    height: 30vh;
    width : 20vw;
    position : relative; */

}



.introMVSize{

  width:100%;
  height:100%;
  object-fit:cover;


  /* filter : blur(3px)
}


/* .introMVDiv:before{
  content : '';
  background: url('./../../public/MVIMG.png') no-repeat center/cover;
  background-size: cover;
  background-repeat: no-repeat;
  height: 18vh;
  width : 100%;
  position : absolute;
  display: flex;
  top : 0;
  left : 0;
  z-index : auto;

}
 */


}



@media screen and (max-width: 991px) {



  .introTextWrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }




   .introMVDiv{


    width : 100px;
    height : 100px;
    position : static;

    top : 0%;
    left : 10%;
    border-radius: 16px;
   }


.introImgDivTest{

  
  height: auto;

}



.introImgDiv{

background-color: white;

justify-content: center;
display: flex;

position : static;

border-radius: 16px;
padding : 5%;


height: 150px;
width: 20vw;





  }








.introCCTV1{

font-size: 40px;



font-family: 'Noto Sans KR';
font-weight: 700;

color: white;

}

.introCCTV2{
font-size: 1.2rem;
margin-bottom: 30px;
color: white;

line-height: 1.5;

font-family: 'Noto Sans KR';
font-weight: 500;


}

.introCCTV3{
margin-bottom: 30px;

color: white;

font-family: 'Noto Sans KR';
font-weight: 500;
font-size: 1.2rem;



}


.introContents{
color: black;

font-family: 'Noto Sans KR';
font-weight: 400;
font-size: 0.7rem;
line-height: 0.9;
text-align: left;



}



.introContentsContainer{
  padding : 30px;
  height : 620px;
  padding-top: 50px;
}


.dnationCEO{
color: white;

font-family: 'Noto Sans KR';
font-weight: 300;
font-size: 0.8rem;

text-align: center;
padding-top: 40px;


line-height: 18px;

}

.introLogo{

color: white;

font-family: 'Noto Sans KR';
font-weight: 300;
font-size: 1rem;
text-align: right;

}





.introMVDiv{
  background-color: white;

  justify-content: center;
  display: flex;

  position : static;

  top : 0%;
  left : 0%;
  border-radius: 16px;

  background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5)), url('./../../public/MVIMG.png');


  background-size: cover;
  background-repeat: no-repeat;

  
  width: 20vw;
  height: 150px;


}




.introMVicon{

  z-index: 2;
  margin-top: 4vh;
  width: 80px;
  height: 80px;
 }


.introMViconBox{



  margin-top: 2.7vh;



}



.introImgSize{

  height: 120px;
  padding-top: 3vh;

}




  }




  
@media screen and (max-width: 768px) {
    .introTextWrapper {
      padding-bottom: 65px;
    }

    .col {
      max-width: 100%;
      flex-basis: 100%;
    }

    .intro__Backgound_color{

      padding : 10px 0;
      height: 1350px;

     }




     
     .introMVDiv{

      justify-content: center;
      display: flex;

      width : 270px;
      height : 160px;
      position : static;

   
      border-radius: 16px;
     }



.introImgDivTest{
  
  justify-content: center;
display: flex;

position : static;

width : 270px;


}

.introImgDiv{

  background-color: white;


  

  height: 160px;
  width: 270px;
  padding-top: 1%;
  border-radius: 16px;
  
  justify-content: center;
display: flex;

position : static;

  



    }

    .introImgSize{


      height: 120px;


    }





.introCCTV1{

  font-size: 40px;



  font-family: 'Noto Sans KR';
  font-weight: 700;

  color: white;

}

.introCCTV2{
  font-size: 2rem;
  margin-bottom: 30px;
  color: white;

  line-height: 1.5;

  font-family: 'Noto Sans KR';
  font-weight: 500;


}

.introCCTV3{
  margin-bottom: 30px;

  color: white;

  font-family: 'Noto Sans KR';
  font-weight: 500;
  font-size: 2rem;



}


.introContents{
  color: black;

  font-family: 'Noto Sans KR';
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 0.7;
  text-align: center;




}

.dnationCEO{
  color: white;

  font-family: 'Noto Sans KR';
  font-weight: 300;
  font-size: 1rem;

  text-align: center;

}

.introLogo{

  color: white;

  font-family: 'Noto Sans KR';
  font-weight: 300;
  font-size: 1rem;
  text-align: right;

}
.introContentsContainer{

  height: 550px;
  width: 400px;
  border-radius: 30px;

  margin-bottom: 25px;

}


 






  }