/* .mainBody{
    background: white;
    width: 100vw;
    height: 100vh;
}




.bodyText{
    background: red;

    padding : 50px ;
    margin: 50px;
    text-align: center;


} */




.hero{

    background-color: rgb(0,0,0,.7);
    color : #fff;
    height: 100vh;
    width : 100%;
    position : relative;


}


.hero:before {
    content : '';
    background: url('./../../public/back2.jpg') no-repeat center/cover;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width : 100%;
    position : absolute;
    top : 0;
    left : 0;
    z-index : -1;

}


