

.referenceLogoWrap{

    width: 100vw;
    height: 15vh;
    
    

    padding: 20px;

    /* display: flex;

    justify-content: right; */

}

.referenceLogo{


    width: 100px;

}




.referenceText1 {

    color: white;

font-family: 'Noto Sans KR';
font-weight: 700;
font-size: 5rem;

padding-top : 25px;

text-align: center;

    
}

.referenceText2 {


    color: white;

font-family: 'Noto Sans KR';
font-weight: 700;
font-size: 3rem;
line-height: 3;
text-align: center;

    /* background: linear-gradient(

        to bottom,
        transparent 50%,
        yellow 50%

    );
     */

}
.referenceText2re{
    color: white;

    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 3rem;
    line-height: 3;
    text-align: center;

        background: linear-gradient(

        to bottom,
        transparent 95%,
        white 50%

    );
     
}



.social2{
    background: linear-gradient(

        to bottom,
        transparent 45%,
        yellow 50%

    );
}

.social1{
    background: linear-gradient(

        to bottom,
        transparent 95%,
        yellow 50%

    );
}




.RoadWait2{
    color: black;

    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 4.5rem;


}



.ImgTitle{
    color: black;

    font-family: 'Noto Sans KR';
    font-weight: 500;
    font-size: 2.5rem;
    
    text-align: center;

}

.ImgContent{
    color: black;

    font-family: 'Noto Sans KR';
    font-weight: 200;
    font-size: 2rem;
    
    text-align: center;
}


.cardWrapperRefer{

background-color: white;
border-radius: 20px;

margin: 10px;


}

.CardImgRe {

    border-radius: 10px;
    height: 400px;
    padding: 0;
    

}



.CardImgReInner{


    height: 20px;

}



.CardImgRe2{


    height: 400px;

}


@media screen and (max-width: 768px) {

 


.cardWrapperRefer{


    


}


.referenceLogoWrap2{
    display: flex;
    justify-content: right;
}

    .referenceLogoWrap{

        width:18vw;
        
        margin-bottom: 0;
        height: 50px;

        
    
    }
    
    .referenceLogo{
        
    
        
    
    }
    
    
    
    
    .referenceText1 {
    
  
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2;
    
        
    }
    
    .referenceText2 {
    
    
    
    font-size: 2rem;
    line-height: 3;
    text-align: center;
    
        /* background: linear-gradient(
    
            to bottom,
            transparent 50%,
            yellow 50%
    
        );
         */
    
    }
    .referenceText2re{
    
        font-size: 2rem;
        line-height: 3;
         
    }
    




        .CardImgRe {

            border-radius: 10px;
            overflow:hidden;
            

            max-height: 340px;
        }


        .CardImgRe2{


            height: 350px;

        }



        .CardImgReinner{

            width:100%;
            height:85%;
            padding-bottom: 0;

        }


        
          .RoadWait{
            padding-bottom: 40px;

          }

          .RoadWait2{


            font-size: 35px;
          }
          
            .ImgTitle{

                font-size: 30px;

            }

            .ImgContent{

                font-size: 20px;
            }

            .cardWrapperRefer{
                height: 350px;
                
            }

            
.referenceLogoWrap2{
    width: 100vw;
    height: 80px;
}

.referenceLogoWrap{
    width: 100px;
}

}


.CardImgRe2{


    height: 250px;
    border-radius: 20px;

}
.CardImgRe{

    max-height: 280px;

}


/* 

@media screen and (max-height: 900px) {

 


    .CardImgRe{
    
        width: 100%;
        
    
    }
    
    .cardWrapperRefer{
    
    
        height: 450px;
        width: 300px;
    
    
    }

} */

@media screen and (max-width: 991px) {


.CardImgReInner{

    
    
    height: 50%;


    


}


    .CardImgRe{
    
        
        

        
        padding: 0;

    }
    
    .cardWrapperRefer{
    
    
        height: 420px;
        width: 300px;
    
    
    }

.CardDetail{

    padding: 0;
    

}


}
