.container{
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 100px;

}


.contact-box{
	max-width: 700px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #fff;
	box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.19);

    border-radius: 20px;
    

}
 

.content{
	padding: 25px 50px;
}

 


.inputBox{
	width: 100%;
	border: 2px solid rgba(0, 0, 0, 0);
	outline: none;
	background-color: rgba(228, 230, 230, 0.6);
    
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	margin-bottom: 35px;
	transition: .3s;

 
    border-radius: 10px;

}


.qnaText{

    margin-bottom: 10px;

    font-family: 'Noto Sans KR';
    font-weight: 500;
    
    font-size: 1.2rem;
  
    color: black;

}



.inputBox:hover{
	background-color: rgba(0, 0, 0, 0.1);
}




textarea{
	min-height: 150px;
}



.clickbox{
	width: 100%;
	padding: 0.5rem 1rem;
	background-color: #103F83;
	color: #fff;
	font-size: 1.1rem;
	border: none;
	outline: none;
	cursor: pointer;
	transition: .3s;
	border-radius: 10px;


}

.clickbox:hover{
    background-color: black;


}

.inputBox:focus{
    /* border: 1px  rgba(30,85,250,0.47); */
    background-color: #fff;
}





@media screen and (max-width: 880px){


	
	.contact-box{
		grid-template-columns: 1fr;
	}




 
}



@media screen and (max-width: 768px) {

	.contact-box{

		
		padding: 1px;
		width: 93vw;
		
	}

	.qnaText{

		width: 200px;

		font-weight: 400;
    
		font-size: 1rem;

	}


	.inputBox{

		width: 280px;

	}

	.container{
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1px 1px;
	
	}
	
	

}