.box {

    width: 415px;
position: relative;
min-width: 150px;
min-height: 40px;
background: rgba(255, 255, 255, 0.1);

border-radius: 10px;
z-index: 10;





border : 1px solid rgba(255,255,255,0.5);


backdrop-filter: blur(25px);


}

.boxLine{

padding: 0;
margin-right: 30px;
margin-left: 30px;

}


.box h3 {

    color : white;
}


.list {


    position: relative;
    
    padding : 5px;
    background: #EEEEEE;
    border-radius: 10px;
    margin : 15px ;
    cursor: pointer;


}

.box .list:hover {
    background: white;
    box-shadow:  2px 2px 2px rgba(0,0,0,0.5);
    transform : scale(1.02);
}


.box .list .imgBx{

    
    position: relative;

    width : 120px;
    height : 30px;
    border-radius: 5px;

    overflow: hidden;

    margin-right: 1px;

}


.box .list .imgBx img {
    
    position: absolute;
    top : 0;
    left : 0;
    width : 100%;
    height: 100%;
    object-fit: cover;


}

.ReviewContent{
    
    padding: 3px;

}


.box .list:hover .content h4,
.box .list:hover .content p{

    color : red;
}



.starContexts1{

    font-size: 70px;
    font-family: 'Noto Sans KR';
    font-weight: 500;
    
    color: black;
    

  }
  .starContexts2{


    font-size: 40px;
    font-family: 'Noto Sans KR';
    font-weight: 300;
    
    color: black;

    margin-right: 40px;

  }
  .starContexts3{


    font-size: 1rem;
    font-family: 'Noto Sans KR';
    font-weight: 300;
    
    color: black;

  }


  .startContextBox{

    display: flex;
    justify-content: center;
    
    margin-top: 150px;


  }

  .startContextBoxIn{

  
display: flex;
justify-content: center;
margin-bottom: 50px;
  }

  .ReviewContentText{
    font-size: 1rem;
    font-family: 'Noto Sans KR';
    font-weight: 300;
    
    color: black;



  }

  .ReviewContentText2{

    font-size: 1rem;
    font-family: 'Noto Sans KR';
    font-weight: 200;
    
    color: black;


display: flex;
justify-content: right;
  }





  @media screen and (max-width: 768px) {




    .list {


      position: relative;
      
      padding : 5px;
      background: #EEEEEE;
      border-radius: 10px;
      margin : 1px ;
      cursor: pointer;
  
  
  }




    .rec.rec-carousel-wrapper .rec-carousel button {

      
    }
    .rec.rec-carousel{

      
    }


    .box {

      width: 236px;
  position: relative;
  
  
  background: rgba(255, 255, 255, 0.1);
  
  border-radius: 10px;

  

    
  
  border : 1px solid rgba(255,255,255,0.5);
  
  backdrop-filter: blur(25px);

  background-color: #14487B;
  
  }




  
  .boxLine{
  
  padding: 0;
  margin: 0;
  
  
  
  }
  
  
  .box h3 {
  
      color : white;
  }
  



 
  
  .box .list:hover {
      background: white;
      box-shadow:  2px 2px 2px rgba(0,0,0,0.5);
      transform : scale(1.02);
  }
  
  
  .box .list .imgBx{
  
      
      position: relative;
  
      width : 120px;
      height : 30px;
      border-radius: 10px;
  
      overflow: hidden;
  
      margin-right: 1px;
  
  }
  
  
  .box .list .imgBx img {
      
      position: absolute;
      top : 0;
      left : 0;
      width : 100%;
      height: 100%;
      object-fit: cover;
  
  
  }
  
  .ReviewContent{
      
      padding: 3px;
  
  }
  
  
  .box .list:hover .content h4,
  .box .list:hover .content p{
  
      color : red;
  }
  
  
  
  .starContexts1{
  
      font-size: 40px;
      font-family: 'Noto Sans KR';
      font-weight: 500;
      
      color: black;
  
    }
    .starContexts2{
  
      
      font-size: 17px;
      font-family: 'Noto Sans KR';
      font-weight: 200;
      
      color: black;
  
      margin-right: 10px;
  
    }
    .starContexts3{
  
  
      font-size: 1rem;
      font-family: 'Noto Sans KR';
      font-weight: 300;
      
      color: black;
  
    }
  
  
    .startContextBox{
  
      display: flex;
      justify-content: center;
      
      margin-top: 150px;
      margin-bottom: 20px;
  
    }
  
    .startContextBoxIn{
  
   
  
    }

  
    .ReviewContentText{
      font-size: 2px;
      font-family: 'Noto Sans KR';
      font-weight: 100;
      
      color: black;
  
  
  
    }
  
    .ReviewContentText2{
  
      font-size: 2px;
      font-family: 'Noto Sans KR';
      font-weight: 200;
      
      color: black;
  
  
  display: flex;
  justify-content: right;
    }
  
  

  }


